<template>
    <v-container>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
       </v-skeleton-loader>
        <v-data-table v-if="filteredCompanies!=null" :items="filteredCompanies"
        :headers="this.companiesHeader"
        :search="companiesSearch"
        class="elevation-1"
        :footer-props="{
            'items-per-page-options':[10,20,50,100]
        }"
        dense
        >
         <template v-slot:item.index="{item}">
            {{ companies.indexOf(item)+1 }}
        </template>

        <template v-slot:item.company_name="{item}">
            <router-link
                v-if="item.company_name!=null && item.company_name!=''" :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                {{ item.company_name }}
            </router-link>
            <router-link
                v-else
                :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                -
            </router-link>
        </template>
        <template v-slot:top>
            <v-toolbar flat height="auto">
                <v-row no-gutters>
                    <v-col class="pt-5 mt-5">
                        <v-row>
                            <v-col>
                                <h1>
                                    Companies
                                </h1>
                            </v-col>

                        </v-row>

                        <v-row class="" >
                            <v-col
                                class="">
                                <v-row>
                                    <v-col class="pr-0">
                                        <span class="mt-5 grey--text text-h7 mb-2">
                                            Subscribed Date :
                                        </span>
                                        <v-row>
                                            <v-col class="pr-0">
                                                <div>
                                                    <v-text-field
                                                        @click:prepend-inner="()=>{
                                                            isStartDate = true;
                                                        }"
                                                        prepend-inner-icon="mdi-calendar"
                                                        @click="()=>{
                                                            isStartDate = true;
                                                        }"
                                                        style="border-radius:0px"
                                                        label="Start Date"
                                                        v-model="startDate"
                                                        dense
                                                        outlined>

                                                    </v-text-field>
                                                    <v-dialog v-model="isStartDate" height="auto" width="auto">
                                                        <v-card class="pa-3">
                                                            <p>
                                                                Start date
                                                            </p>
                                                            <v-date-picker flat v-model="startDate" :landscape="true" :reactive="true">
                                                            </v-date-picker>
                                                            <div name="footer" class="footer">
                                                                <div class="d-flex justify-end my-3">
                                                                    <v-btn color="primary" plain @click="()=>{
                                                                        isStartDate = false;
                                                                        startDate = null;
                                                                    }">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn color="primary" @click="()=>{
                                                                        isStartDate = false;
                                                                    }">
                                                                        Confirm
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                    </v-dialog>
                                                </div>
                                            </v-col>
                                            <v-col class="pl-0">
                                                <div>
                                                    <v-text-field @click:prepend-inner="()=>{
                                                        isEndDate = true;
                                                    }" prepend-inner-icon="mdi-calendar" @click="()=>{
                                                        isEndDate = true;
                                                    }" style="border-radius:0px" label="End Date" v-model="endDate"  dense outlined>
                    
                                                    </v-text-field>
                                                    <v-dialog v-model="isEndDate" height="auto" width="auto">
                                                        <v-card class="pa-3">
                                                            <p>
                                                                End Date
                                                            </p>
                                                            <v-date-picker flat v-model="endDate" :landscape="true" :reactive="true">
                                                            </v-date-picker>
                                                            <div name="footer" class="footer">
                                                                <div class="d-flex justify-end my-3">
                                                                    <v-btn color="primary" plain @click="()=>{
                                                                        isEndDate = false;
                                                                        endDate = null;
                                                                    }">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn color="primary" @click="()=>{
                                                                        isEndDate = false;
                                                                    }">
                                                                        Confirm
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                    </v-dialog>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="pl-0">
                                        <span class="mt-5 grey--text text-h7 ml-2 mb-2 ">
                                            Expired Date :
                                        </span>
                                        <v-row>
                                            <v-col class="pr-0">
                                                <div>
                                                    <v-text-field prepend-inner-icon="mdi-calendar" v-model="startDateExpired" style="border-radius:0px" @click:prepend-inner="()=>{
                                                        isStartDateExpired = true;
                                                    }" label="Start date" @click="()=>{
                                                        isStartDateExpired = true;
                                                    }" dense outlined>
                    
                                                    </v-text-field>
                                                    <v-dialog v-model="isStartDateExpired" height="auto" width="auto">
                                                        <v-card class="pa-3">
                                                            <p>
                                                                Start Date
                                                            </p>
                                                            <v-date-picker flat v-model="startDateExpired" :landscape="true" :reactive="true">
                                                            </v-date-picker>
                                                            <slot name="footer">
                                                                <div class="d-flex justify-end white" >
                                                                    <v-btn color="primary" plain @click="()=>{
                                                                        isStartDateExpired=false;
                                                                        startDateExpired = null;
                                                                    }">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn color="primary" @click="()=>{
                                                                        isStartDateExpired = false;
                                                                    }">
                                                                        Confirm
                                                                    </v-btn>
                                                                </div>
                                                            </slot>
                                                        </v-card>
                                                    </v-dialog>
                                                </div>
                                            </v-col>
                                            <v-col class="pl-0">
                                                <div>
                                                    <v-text-field @click:prepend-inner="()=>{
                                                        isEndDateExpired = true;
                                                    }" prepend-inner-icon="mdi-calendar" @click="()=>{
                                                        isEndDateExpired = true;
                                                    }" style="border-radius:0px 4px 4px 0px" label="End Date" v-model="endDateExpired"  dense outlined>
                                                    </v-text-field>
                                                    <v-dialog v-model="isEndDateExpired" height="auto" width="auto">
                                                        <v-card class="pa-3">
                                                            <p>
                                                                End Date
                                                            </p>
                                                            <v-date-picker flat v-model="endDateExpired" :landscape="true" :reactive="true">
                                                            </v-date-picker>
                                                            <div name="footer" class="footer">
                                                                <div class="d-flex justify-end my-3">
                                                                    <v-btn color="primary" plain @click="()=>{
                                                                        isEndDateExpired = false;
                                                                        endDateExpired = false;
                                                                    }">
                                                                        Cancel
                                                                    </v-btn>
                                                                    <v-btn color="primary" @click="()=>{
                                                                        isEndDateExpired = false;
                                                                    }">
                                                                        Confirm
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </v-card>
                                                    </v-dialog>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row class="" >
                            <v-col
                                class="pr-0">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                class="pr-0">
                                                <v-select
                                                style="border-radius:0px"
                                                dense 
                                                outlined 
                                                label="Filter subscription type"
                                                v-model="subscriptinTypeFilter"
                                                :items="subscriptionTypeOptions">
        
                                                </v-select>
        
                                            </v-col>
                                            <v-col cols="12" sm="6"
                                            class="pb-0 pl-0">
                                                <v-select class=""
                                                style="border-radius:0px" dense outlined label="Filter subscription status" v-model="subscriptionFilter" item-text="text" item-value="value" :items="subscriptionOptions" >
                    
                                                </v-select>
                                            </v-col>
                                          
                                         
        
                                        </v-row>
                                    </v-col>

                                </v-row>
                            </v-col>
                            <v-col
                                class="pl-0">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12">
                                        <v-row>
                                            <v-col cols="12" sm="6"
                                                class="pr-0">
                                                <v-select style="border-radius:0px"  dense outlined label="Filter renewal status" v-model="renewalFilter" item-text="text" item-value="value"  :items="renewalOptions">
                    
                                                </v-select>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                class="pl-0">
                                                <v-select class=""                                         style="border-radius:0px"
                                                    dense outlined label="Filter company status" v-model="companyFilter" item-text="text" item-value="value" :items="companyOptions">
                                                </v-select>
        
                                            </v-col>
                                          
                                           
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>
                                


                            <v-col cols="12" sm="12" class="pt-0">
                                <v-text-field class="" dense outlined label="Search..." v-model="companiesSearch">
    
                                </v-text-field>    
                            </v-col>
                            <v-col cols="12" sm="12" class="mb-5 pt-0">
                                <div class="d-flex justify-space-between pb-5">
                                    <JsonCSV
                                        :data="filteredCompanies">
                                        <v-btn color="primary"
                                        text>
                                            Download
                                        </v-btn>
                                    </JsonCSV>
                                    <div>
                                        <div class="px-2">
                                            <v-btn color="primary"
                                            class="mx-2" outlined @click="resetFilter">
                                                Reset Filter
                                            </v-btn>
                                            <v-btn color="primary" class="elevation-0" @click="filterCompaniesSubscriptionDate"  
                                            >
                                                Filter
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-toolbar>
        </template>

        <template v-slot:item.created_at="{item}">
            {{ convertTimeZone(item.created_at) }}
        </template>
        <template v-slot:item.period_start_date="{item}">
            <span
                v-if="item.period_start_date!=null">
                    {{ convertTimeZone(item.period_start_date) }}
            </span>
            <span
                v-else>
                -
            </span>
        </template>
        <template v-slot:item.period_end_date="{item}">
            <span
                v-if="item.period_end_date!=null">
                {{ convertTimeZone(item.period_end_date) }}
            </span>
            <span
                v-else>
            </span>
        </template>
    </v-data-table>
    </v-container>
</template>
<script>
import { mapState } from 'vuex';

export default{
    components:{

    },
    computed:mapState({

    }),
    props:[
        'status'
    ],
    data:()=>({
        companiesSearch:null,
        isEndDateExpired:false,
        isStartDateExpired:false,
        startDateExpired:null,
        endDateExpired:null,
        isEndDate:false,
        endDate:null,
        isStartDate:false,
        startDate:null,
        subscriptionFilter:"all",
        subscriptinTypeFilter:'all',
        companyFilter:"all",
        renewalFilter:"all",
        renewalOptions:[
            {
                text:'All',
                value:"all"
            },
            {
                text:'Expiring in less than 30 days',
                value:"Expiring in less than 30 days"
            },
            {
                text:'Expiring in less than 90 days',
                value:'Expiring in less than 90 days',
            },
            {
                text:'Expiring in more than 90 days',
                value:'Expiring in more than 90 days',
            },
            {
                text:'Expired',
                value:'expired',
            }
        ],

        subscriptionTypeOptions :[
            {
                text:'All',
                value:'all',
            },
            {
                text:'First subscriber',
                value:'First subscriber',
            },
            {
                text:'Renewal',
                value:'Renewal'
            },
        ],
        subscriptionOptions : [
            {
                text:'All',
                value:'all'
            },
            {
                text:'Active',
                value:'active'
            },
            {
                text:'Canceled',
                value:'canceled',
            },
            {
                text:'Incomplete',
                value:'incomplete',
            }

        ],
        companyOptions: [
            {  
                text:'All',
                value:'all'
            },
            {
                text:'Valid',
                value:'valid',
            },
            {
                text:'Invalid',
                value:'invalid',
            },
            {
                text:'Unknown',
                value:'unknown',
            },
            {
                text:'Internal',
                value:'internal'
            }
        ],
        api : {
            isLoading:false,
            isError:false,
            error:null,
            url:null,
        },
        filteredCompanies:null,
        companies:null,
        totalCompanies:null,
        companiesHeader:[
            {
                text:'#',
                value:'index',
                align:'start',
            },
            {
                text:'Company Name',
                value:'company_name',
            },
            {
                text:'Plan',
                value:'plan_name',
            },
            {
                text:"Subscription type",
                value:'subscription_category',
            },
            {
                text:'Subscription status',
                value:'stripe_status'
            },
            {
                text:'Renewal status',
                value:'renewal_status',
            },
            {
                text:'Company status',
                value:'verification'
            },
            {
                text:'Subscribed Start Date',
                value:'period_start_date',
            },
            {
                text:'Subscription Expired Date',
                value:'period_end_date',
            },
        
        ]
    }),
    created(){
        this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class==="getCompanies") {
                this.companies = resp.data;
                this.filteredCompanies = resp.data;
                this.totalCompanies = Object.keys(resp.data).length;
            }

            if(resp.class=="getCompaniesClientActiveAssigned"){
                this.companies = resp.data.companies;
                let tempCompanies;
                if(this.status=='Active'){
                    tempCompanies = this.companies.filter(function(item) {
                        if(item.stripe_status==='active' && item.verification.toLowerCase()!=='expired') {
                            return true;
                        }
                    });

                    // tempCompanies = tempCompanies.filter(function(item){
                    //     if(item.verification!=='Expired'){
                    //         return true;
                    //     }
                    // })
                }

                if(this.status=='Expired'){
                    tempCompanies = this.companies.filter(function(item){
                        if(item.stripe_status.toLowerCase()==='canceled' || item.verification.toLowerCase()==='expired'){
                            // item.verification =  item.stripe_status;
                            return true;
                        }
                    })
                }

                this.filteredCompanies = tempCompanies
                this.totalCompanies = Object.keys(tempCompanies).length;
            }
            if(resp.class=="getCompaniesClientActive"){
                this.companies = resp.data.companies;
                let tempCompanies;
                if(this.status=='Active'){
                    // BOC : If current tab is showing the active companies
                    tempCompanies = this.companies.filter(function(item) {
                        if(item.stripe_status==='active' && item.verification_period.toLowerCase()!=='expired') {
                            // BOC : If the companies has a status of the subscription as active, then return true;     
                            return true;
                        }
                    });
                }

                if(this.status=='Expired'){
                    // BOC : If current tab is showing the expired companies
                    tempCompanies = this.companies.filter(function(item){
                        if(item.stripe_status.toLowerCase()==='canceled' || item.verification_period.toLowerCase()==='expired'){
                            // item.verification = item.stripe_status.charAt(0).toUpperCase() + item.stripe_status.slice(1);
                            return true;    
                        }
                    })
                }

                this.filteredCompanies = tempCompanies
                this.totalCompanies = Object.keys(tempCompanies).length;
                // this.totalCompanies = Object.keys(this.filteredCompanies).length;

            }

            this.api.isLoading = false;
        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            this.$api.fetch(this.fetchListOfCompanies());
            // let salesRoles = ['salesperson','sales_team_lead','marketing'];
            // let allowedRolesAllCompanies = ['customer_service','vision_admin','developer','operation','sales_manager'];
            // let fetchCompaniesApi = this.fetchCompanies();
            
            // if(allowedRolesAllCompanies.some(role=>this.$store.getters.getRole.includes(role))){
            //     this.$api.fetch(fetchCompaniesApi);
            //     return 1;

            // }

            // if( salesRoles.some(role => this.$store.getters.getRole.includes(role))){
            //     let fetchPICCompaniesApi = this.fetchCompaniesAssigned();
            //     this.$api.fetch(fetchPICCompaniesApi);

            //     return 1;

            // }

        },
        fetchListOfCompanies() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API.replace("v1","v3")+"/companies";
            return tempApi;
        },

        fetchCompaniesAssigned(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/pic/company?userId='+this.$store.getters.getUserId;
            return tempApi;
        },
        fetchCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/company/subscription/'+this.status+'?role='+this.$store.getters.getRole+'&userId='+this.$store.getters.getUserId;
            return tempApi;
        },
        filterCompaniesRenewal(){
            this.startDate = null;
            this.endDate = null;
            let tempCompanies = this.$_.clone(this.companies);
            let filter = this.$_.clone(this.renewalFilter);
            let filteredCompanies = tempCompanies.filter(function(item) {
                if(filter.value===0){
                    return true;
                }
                if(filter.value===1){
                    return (item.renewal_status==='Expiring in less than 30 days');
                }
                if(filter.value===2){
                    return (item.renewal_status==='Expiring in less than 90 days');
                }
                if(filter.value===3){
                    return (item.renewal_status==="Expiring in more than 90 days");
                }
            })
            this.filteredCompanies = filteredCompanies
            // this.totalCompanies = Object.keys(this.filteredCompanies).length
           
        },
        filterCompaniesSubscription() {
            let tempCompanies = this.$_.clone(this.companies);
            let filter = this.$_.clone(this.subscriptionFilter);
            if(filter ==='all') {
                return true;
            }
            let filteredCompanies = tempCompanies.filter(function(item) {
                return (item.stripe_status===filter.value);
            });
            this.filteredCompanies = filteredCompanies;
        },
        filterCompanyStatus() {
            let tempCompanies = this.$_.clone(this.companies);
            let filter = this.$_.clone(this.companyFilter);
            if(filter ==='all') {
                return true;
            }
            let filteredCompanies = tempCompanies.filter(function(item) {
                return (item.verification ===filter.value);
            });
            this.filteredCompanies = filteredCompanies;
        },
        filterCompaniesSubscriptionDate(){
            let tempCompanies = this.$_.clone(this.companies);
            let startDate = this.$_.clone(this.startDate);
            let endDate = this.$_.clone(this.endDate);
            let startDateExpired = this.$_.clone(this.startDateExpired);
            let endDateExpired = this.$_.clone(this.endDateExpired);

            let filter = null;
            if(this.companyFilter !=='all') {
                filter = this.companyFilter;
                tempCompanies = tempCompanies.filter(function(item) {
                    return (item.verification ===filter);
                });
                filter = null;
            }

            if(this.subscriptinTypeFilter !=='all') {
                filter = this.$_.clone(this.subscriptinTypeFilter);
                tempCompanies = tempCompanies.filter(function(item) {
                    return (item.subscription_category===filter);
                });
                filter = null;
            }

            try{
                alert(this.subscriptionFilter);
                if(this.subscriptionFilter !=='all') {
                    filter = this.$_.clone(this.subscriptionFilter);
                    tempCompanies = tempCompanies.filter(function(item) {
                        return (item.stripe_status===filter);
                    });
                    filter = null;
                }

            }catch(error) {
                alert(error);
            }
            if(this.renewalFilter!="all") {
                filter = this.$_.clone(this.renewalFilter);
                tempCompanies = tempCompanies.filter(function(item) {
                    return (item.renewal_status ===filter);
                })
                filter = null
            }

            if(startDate!=null){

                tempCompanies = tempCompanies.filter(function(item){
                    let tempSubscriptionDate = (item.period_start_date!=null)?item.period_start_date.split(" ")[0]:null;
                    if(tempSubscriptionDate>=startDate){
                        return true;
                    }
                })
            }

            if(endDate!=null){
                tempCompanies = tempCompanies.filter(function(item){
                    let tempSubscriptionDate = (item.period_start_date!=null)?item.period_start_date.split(" ")[0]:null;
                    if(tempSubscriptionDate<=endDate){
                        return true;
                    }
                })
            }

            if(startDateExpired!=null){
                tempCompanies = tempCompanies.filter(function(item){
                    let tempExpiredDate = (item.period_end_date!=null)?item.period_end_date.split("T")[0]:null;
                    if(tempExpiredDate>=startDateExpired){
                        return true;
                    }
                })
            }


            if(endDateExpired!=null){
                tempCompanies = tempCompanies.filter(function(item){
                    let tempExpiredDate = (item.period_end_date!=null)?item.period_end_date.split("T")[0]:null;
                    if(tempExpiredDate<=endDateExpired){
                        return true;
                    }
                })
            }

            this.filteredCompanies = tempCompanies;

            
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
            // return new Date(new Date(time).toLocaleString("en-US",{timeZone:"Asia/Kuala_Lumpur"})).toString();
        },
        resetFilter(){
            let tempCompanies = this.$_.clone(this.companies);


            if(this.status=='Active'){
                    tempCompanies = tempCompanies.filter(function(item){
                        if(item.verification!=='Expired'){
                            return true;
                        }
                    })
                }

            if(this.status=='Expired'){
                tempCompanies = this.companies.filter(function(item){
                    if(item.verification==='Expired'){
                        return true;
                    }
                })
            }
            this.filteredCompanies = tempCompanies;
            this.startDateExpired=null;
            this.endDateExpired=null;
            this.startDate = null;
            this.endDate = null;
            this.subscriptionFilter = "all";
            this.subscriptinTypeFilter = "all";
            this.renewalFilter = "all";
            this.companyFilter = "all";
            this.companiesSearch = null;
        }
    }
}
</script>