<template>
    <v-container>
       <AError :api="this.api" />
 
       <v-container class="" v-if="this.allowedRoles.some(role => this.$store.getters.getRole.includes(role))">
            <v-row>
                <v-col>
                    <PageCompaniesListSubscription/>
                </v-col>
            </v-row>
       </v-container>
       <v-container v-else>
          <div>
             <v-toolbar flat class="">
                <v-tabs v-model="subscriptionTab" class="d-flex justify-center">
                   <v-tab>
                      Active
                   </v-tab>
                </v-tabs>
             </v-toolbar>
             <v-tabs-items v-model="subscriptionTab">
                <v-tab-item>
                   <PageCompaniesListSubscription/>
                </v-tab-item>
             </v-tabs-items>
          </div>
       </v-container>
    </v-container>
 </template>
 <script>
 import PageCompaniesListSubscription from '../analysis/PageCompaniesListSubscription.vue';
 
 
 export default {
    components: {
       PageCompaniesListSubscription
    },
    computed: {
 
    },
 
    data: () => ({
       subscriptionTab: null,
       companyTab: null,
       userRole: null,
       allowedRoles: ['developer', 'vision_admin', 'operation', 'customer_service', 'sales_manager'],
       toggleNone: null,
       isSorted: {
          name: {
             asc: false,
             desc: false,
          },
          salesperson: {
             asc: false,
             desc: false,
          },
          created: {
             asc: false,
             desc: false,
          },
       },
       firstLoading: {
          isLoading: true,
          isSuccesful: false,
       },
       sort: {
          order: 'sortDesc',
          column: 'iv.created_at',
       },
       headers: [
          {
             text: '#',
             value: 'index',
             align: 'start',
             sortable: false,
          },
          {
             text: 'Name',
             value: 'name',
             sortable: false,
          },
          {
             text: 'Subscription',
             value: 'plan_name',
          },
          {
             text: 'Subscription Status',
             value: 'status',
             sortable: false,
          },
          {
             text: 'Subscribe Date',
             value: 'created_at',
             sortable: false,
          },
 
 
       ],
       datepicker: false,
       companyName: null,
       salespersonName: null,
       data: [null],
       plan_available: [null],
       salesPerson: [null],
       dialog: false,
       dialogNote: false,
       editedCompanies: {
          id: null,
          created_at: null,
          name: null,
          plan: {
             id: null,
             plan_name: null,
          },
          renew_at: null,
          // followup_at:null,
          status: null,
          address: "",
          companySize: 0,
          industry: null,
          pic: {
             role_id: null,
             mobile: null,
             names: null,
             user_email: null,
          }
       },
       statusSearch: 'All',
       statusCompanies: ['All', 'Active', 'Expired', 'Expires in 30 Days', 'Expires in 90 Days'],
       pageNumber: 1,
       nextPage: 0,
       totalPage: 0,
       totalCompanies: 0,
       api: {
          isLoading: false,
          isError: false,
          error: null,
          url: null,
       },
       promisesFetchCompanies: null,
       promisesFetchPlan: null,
       loadingData: true,
    }),
    created() {
       this.api.method = "get",
          this.api.url = process.env.VUE_APP_SERVER_API + "/getcompanies/renewal";
       this.api.callbackReset = () => {
          this.api.isLoading = true;
          this.api.isError = false;
       };
       this.api.callbackError = (e) => {
          this.api.isLoading = false;
          this.api.isError = true;
          this.api.error = e;
       };
       this.api.callbackSuccess = (resp) => {
          if (resp.class == "getCompaniesClient" || resp.class == "getCompaniesClientBasedOnPIC") {
             this.data = resp.data.companies;
             this.totalPage = resp.data.totalPage;
             this.totalCompanies = resp.data.totalCompanies;
 
             this.firstLoading.isLoading = false;
             this.loadingData = false;
 
          }
          // if(resp.status == "success")
          // {
          //     if(this.api.url== process.env.VUE_APP_SERVER_API+"/companies/getsubscription?status="+this.statusSearch+"&page="+this.nextPage+"&companyName="+this.companyName+"&salespersonName="+this.salespersonName+"&"+this.sort.order+"="+this.sort.column+"&"+this.userRole+"="+this.$store.getters.getEmail){
          //         this.totalPage = resp.total;
          //         this.data = (resp.data)
          //         this.totalCompanies = resp.totalCompanies;
          //     }
          //     if(this.api.url==process.env.VUE_APP_SERVER_API+"/plan/all"){
          //         this.plan_available = JSON.parse(JSON.stringify(resp.data));
          //     }
          //     if(this.api.url==process.env.VUE_APP_SERVER_API+"/user/role/salesperson")
          //     {
          //         this.salesPerson = JSON.parse(JSON.stringify(resp.data));
          //     }
          //     this.loadingData = false;
          // }
       }
    },
    methods:
    {
       clearSearch() {
          this.companyName = null;
          this.salespersonName = null;
 
          this.fetchCompanies(1);
       },
       sortFetchCompanies(order, column) {
          this.sort.order = order;
          this.sort.column = column;
          this.fetchCompanies(1);
       },
       fetchCompanies(page) {
 
          let formData = new FormData();
          let order = this.sort.order;
          let column = this.sort.column;
          this.loadingData = true;
          this.api.method = "POST";
          this.nextPage = this.pageNumber = page;
          this.api.url = process.env.VUE_APP_SERVER_API + "/companies/client";
          let seeAllRoles = ['operation', 'vision_admin', 'customer_service', 'developer', 'sales_manager']
          if (!seeAllRoles.some(role => this.$store.getters.getRole.includes(role))) {
             this.api.url = process.env.VUE_APP_SERVER_API + "/companies/client/pic/email/" + this.$store.getters.getEmail;
 
          }
          // for operation, will get all the list of the companies, but for other roles, will only get the companies being assigned to them.
          // if(this.$store.getters.getRole!='operation' && this.$store.getters.getRole!='vision_admin'&&this.$store.getters.getRole!='developer'){
          // }
          if (this.companyName != null) {
             formData.append('companyName', this.companyName);
          }
          if (this.statusSearch != null) {
             formData.append('status', this.statusSearch)
          }
          formData.append(order, column);
          formData.append("page", this.nextPage);
          this.api.params = formData;
          return this.$api.fetch(this.api);
       },
       fetchPlanAvailable() {
          this.api.url = process.env.VUE_APP_SERVER_API + "/plan/all";
          return this.$api.fetch(this.api);
       },
       fetchSalesPerson() {
          this.api.url = process.env.VUE_APP_SERVER_API + "/user/role/salesperson";
          return this.$api.fetch(this.api);
 
       },
       editCompanies(item) {
          this.dialog = true;
          this.fetchIndividualCompany(item);
       },
       openNotes(item) {
          this.fetchIndividualCompany(item);
          this.dialogNote = true;
       },
       fetchIndividualCompany(item) {
          // BOC : [ construct edited companies object]
          this.editedCompanies.id = item.company_id;
          this.editedCompanies.created_at = item.created_at;
          this.editedCompanies.name = item.name;
          this.editedCompanies.plan.plan_name = item.plan_name;
          this.editedCompanies.plan.id = item.plan_id;
          this.editedCompanies.renew_at = item.renew_at;
          this.editedCompanies.status = item.status;
          this.editedCompanies.pic.role_id = item.salesperson_id;
          this.editedCompanies.pic.names = item.salesperson_name;
          this.editedCompanies.pic.mobile = item.mobile;
          this.editedCompanies.pic.user_email = item.email;
          this.editedCompanies.address = item.address;
          this.editedCompanies.companySize = item.company_size;
          this.editedCompanies.industry = item.industry;
 
          // EOC
       },
       readCompany(item) {
          this.$store.commit("updateCompany", item.company_id);
          this.$router.push({ name: 'PageCompaniesDetail', params: { 'id': item.company_id } });
          // let route = this.$router.resolve({name:'PageCompaniesDetail',params:{'id':item.company_id}});
          // window.open(route.href,'_blank')
       },
       closeDialog() {
          this.dialog = false;
          this.dialogNote = false;
       },
       convertTimeZone(time) {
          return this.$moment(time).format('LLL')
          // return new Date(new Date(time).toLocaleString("en-US",{timeZone:"Asia/Kuala_Lumpur"})).toString();
       },
 
    }
 }
 </script>